import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { navigate } from "gatsby"

import _Layout, { Main } from "../containers/layout"
import SEO from "../containers/seo"
import _Button from "../components/buttons/button"
import image from "../images/illustrations/404.svg"

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO
        title="404: Not found"
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <NotFoundImage />
      <Heading>
        <span>oops!</span> page not found
      </Heading>
      <Info>Perhaps you can try to refresh the page, sometimes it works!</Info>
      <GoBackButton />
    </Layout>
  )
}

const Heading = styled.h2`
  font-size: ${rem("36px")};
  margin-bottom: 0;

  > span {
    color: ${props => props.theme.color.primary};
  }
`
const Info = styled.p`
  font-size: 14px;
`
const NotFoundImage = styled.img.attrs({ src: image, alt: "not found" })`
  width: auto;
  max-width: 100%;
  margin-top: ${rem("72px")};
`
const GoBackButton = styled(_Button).attrs({
  children: "go back",
  onClick() {
    navigate(-1, { replace: true })
  },
})`
  width: ${rem("238px")};
  margin: ${rem("50px")} 0 ${rem("90px")};
`
const Layout = styled(_Layout)`
  ${Main} {
    row-gap: 0;
    text-align: center;
  }

  ${Heading},
  ${Info},
  ${NotFoundImage},
  ${GoBackButton} {
    grid-column: main;
    justify-self: center;
  }
`
